import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useAppContext } from './hooks/useAppContext';

import './App.css'

import Dashboard from './pages/dashboard/Dashboard';
import Sidebar from './components/Sidebar/index';

function App() {
  const { authIsReady, user } = useAppContext();

  return (
    <div className="App">
      {authIsReady && (
        <BrowserRouter>
          {user && <Sidebar />}
          <div className="page-container">
            <Switch>
              <Route exact path="/">
                {user && <Dashboard />}
              </Route>
            </Switch>
          </div>
        </BrowserRouter>
      )}
    </div>
  );
}

export default App
