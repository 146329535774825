import { useEffect, useState } from 'react';
import { OPTIONS } from '../utils/constants';

export const useApiData = (url) => {
  const [ data, setData ] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(`https://${OPTIONS.backend}/api/${url}`, {
      redirect: 'manual',
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      referrerPolicy: 'strict-origin-when-cross-origin'
    }).then(async (response) => {
      const isJson = response.headers.get('content-type')?.includes('application/json');
      const data = isJson ? await response.json() : null;

      if (!response.ok) {
        const error = (data && data.message) || response.status;

        return Promise.reject(error);
      }

      if (response.ok) {
        setData(data);
      }
    }).catch((response, error) => {
      const redirectLink = OPTIONS.sso_url;

      if (response.error === 400) {
        setError(response?.data?.result || response.error);
      } else {
        setError(error || response.error);
      }

      if (response.error === 0 || error === 0 || response === 0) {
        window.location.replace(redirectLink);
      }
    });
  }, []);

  return { data, error }
}
