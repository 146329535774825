import AppList from './appList';
import SidebarHeader from './header';
import SidebarFooter from './footer';

import styles from './sidebar.module.css'

const Sidebar = () => {
  const classes = styles;

  return (
    <aside className={classes.sidebar}>
      <div className={classes.sidebarContent}>
        <SidebarHeader />
        <AppList />
        <SidebarFooter />
      </div>
    </aside>
  )
}

export default Sidebar
