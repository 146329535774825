import { createContext, useEffect, useReducer } from 'react';
import { useApiData } from '../hooks/useApiData';

export const AppContext = createContext();

export const AppReducer = (state, action) => {
    switch (action.type) {
        case 'CURRENT_ITEM':
            return { ...state, selectedItem: action.payload };
        case 'SERVICES':
            return { ...state, services: action.payload };
        case 'LOGIN':
            return { ...state, user: action.payload };
        case 'LOGOUT':
            return { ...state, user: null };
        case 'AUTH_IS_READY':
            return { ...state, user: action.payload, authIsReady: true };
        default:
            return state;
    }
};

export const AppContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(AppReducer, {
        user: null,
        authIsReady: false,
        selectedItem: null,
        services: null
    });

    const { data: userData } = useApiData('users/me');

    useEffect(() => {
        if (userData) {
            const { saasServices: services } = userData;

            if (services && services.length) {
                dispatch({ type: 'SERVICES', payload: services });
            }

            dispatch({ type: 'AUTH_IS_READY', payload: userData });
        }
    }, [userData]);

    return (
        <AppContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AppContext.Provider>
    );
};