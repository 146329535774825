import { useEffect, useState } from 'react';
import { useAppContext } from './useAppContext';

export const useApplicationsList = () => {
    const [list, setList] = useState(null);
    const { services } = useAppContext();

    useEffect(() => {
        if (services && services.length) {
            setList(services);
        }
    }, [services]);

    return { list };
};
