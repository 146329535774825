import { useAppContext } from '../../hooks/useAppContext';

import styles from './Dashboard.module.css'

export default function SelectedItem () {
  const classes = styles;
  const { selectedItem } = useAppContext();

  return (
    selectedItem && (
      <div className={`${classes.selectedItem} page-block`}>
        <h2 className={classes.title}>{selectedItem.title}</h2>
        <p>{selectedItem.description}</p>
        <a className={`${classes.btn} btn`} href={selectedItem.url}>go to application</a>
      </div>
    ) || <div className={`${classes.wrapper} ${classes.title}`}>There are no applications for you</div>
  )
}
