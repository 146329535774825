import { useCallback, useEffect, useState } from 'react';
import { useApplicationsList } from '../../hooks/useApplicationsList';
import { useAppContext } from '../../hooks/useAppContext';

import styles from './sidebar.module.css';

const AppList = () => {
    const classes = styles;
    const { list } = useApplicationsList();
    const { dispatch } = useAppContext();
    const [activeNav, setActiveNav] = useState('');

    const handleChoose = useCallback((item) => {
        if (activeNav !== item.id) {
            setActiveNav(item.id);
            dispatch({ type: 'CURRENT_ITEM', payload: item });
        }
    }, [activeNav, dispatch]);

    // Set active state to current nav item
    useEffect(() => {
        if (list) {
            setActiveNav(list[0].id);
            dispatch({ type: 'CURRENT_ITEM', payload: list[0] });
        }
    }, [list, dispatch]);

    return (
        <nav className={classes.navigation}>
      <ul className={classes.links}>
        <li className={`${classes.item} ${classes.level0}`}>
              <span className={classes.title}>
                List of applications
              </span>
          <ul>
            {list && list.map(item => (
                <li key={item.id} className={`${classes.item} ${activeNav === item.id ? classes.active : ''}`}
                    onMouseEnter={() => handleChoose(item)} onTouchStart={() => handleChoose(item)}
                    onFocus={() => handleChoose(item)}>
                <a href={item.url}>
                  <span className={classes.title}>{item.title}</span>
                </a>
              </li>
            ))}
          </ul>
        </li>
      </ul>
    </nav>
    );
};

export default AppList;
