import { OPTIONS } from '../../utils/constants';
import { NavLink } from 'react-router-dom'

import styles from './sidebar.module.css'

const SidebarHeader = () => {
  const classes = styles;

  return (
    <NavLink className={classes.head} exact to="/">
      <p>{OPTIONS.appName}</p>
      <span>(Beta)</span>
    </NavLink>
  )
}

export default SidebarHeader
